import { createSelector } from 'reselect';
import { dataFormatterUtils } from '../../../utils';

const getBrokenRounds = (state: any) => state.brokenRoundsReducer.data;
const getTotalNumberOfRounds = (state: any) => state.brokenRoundsReducer.totalNumberOfElements;
const getSessionIds = (state: any) => state.brokenRoundsReducer.sessionIds;
const getRoundUuids = (state: any) => state.brokenRoundsReducer.roundUuids;
const getBrokenRoundsMeta = (state: any) => state.brokenRoundsReducer.metadata;
const getStatusHistory = (state: any) => state.brokenRoundsReducer.statusHistory;
const getJobStatus = (state: any) => state.brokenRoundsReducer.isJobRunning;
const getJobUntilTime = (state: any) => state.brokenRoundsReducer.jobUntilTime;

export const getBrokenRoundsData = createSelector(
    getBrokenRounds,
    (state) => state && dataFormatterUtils.getFormattedDataForCP(state),
);
export const getBrokenRoundsMetadata = createSelector(getBrokenRoundsMeta, (state) => state);

export const getTotalNumberOfRoundsData = createSelector(getTotalNumberOfRounds, (state) => state);
export const getSessionIdsData = createSelector(getSessionIds, (state) => state);
export const getRoundUuidsData = createSelector(getRoundUuids, (state) => state);
export const getStatusHistoryData = createSelector(
    getStatusHistory,
    (state) => state && dataFormatterUtils.getFormattedDataForCP(state),
);
export const getJobStatusData = createSelector(getJobStatus, (state) => state);
export const getJobUntilTimeData = createSelector(getJobUntilTime, (state) => state);
