import { IAction } from 'helpers/interfaces';
import { BrokenRoundsTypes } from '../../actions/configProvider/broken-rounds-actions';
import { Messages } from '../../../utils';
import i18next from 'i18next';

export interface IBrokenRoundsData {
    data?: any;
    statusHistory?: any;
    sessionIds: number[] | null;
    roundUuids: string[] | null;
    totalNumberOfElements: number | null;
    isJobRunning: boolean;
    jobFinishUntil: Date | null;
}

export const brokenRoundsData: IBrokenRoundsData = {
    data: null,
    statusHistory: null,
    sessionIds: null,
    roundUuids: null,
    totalNumberOfElements: null,
    isJobRunning: false,
    jobFinishUntil: null,
};

const brokenRoundsReducer = (state = brokenRoundsData, actions: IAction) => {
    switch (actions.type) {
        case BrokenRoundsTypes.BROKEN_ROUNDS_REQUEST: {
            return brokenRoundsData;
        }

        case BrokenRoundsTypes.RETRY_SESSIONS_BATCH_REQUEST: {
            return { ...state, isJobRunning: true };
        }

        case BrokenRoundsTypes.ON_HOLD_BROKEN_ROUNDS_BATCH_REQUEST: {
            return { ...state, isJobRunning: true };
        }

        case BrokenRoundsTypes.UNBLOCK_SESSIONS_BATCH_REQUEST: {
            return { ...state, isJobRunning: true };
        }

        case BrokenRoundsTypes.BROKEN_ROUNDS_JOB_STATUS_REQUEST: {
            return { ...state };
        }

        case BrokenRoundsTypes.BROKEN_ROUNDS_JOB_STATUS_SUCCESS: {
            return {
                ...state,
                jobFinishUntil: actions.payload.finishUntil,
                isJobRunning: actions.payload.status === 'RUNNING',
            };
        }

        case BrokenRoundsTypes.BROKEN_ROUNDS_SUCCESS: {
            const { content, _metadata } = actions.payload;

            return {
                ...state,
                data: content,
                metadata: _metadata,
            };
        }

        case BrokenRoundsTypes.ON_HOLD_BROKEN_ROUNDS_BATCH_SUCCESS: {
            Messages.success(i18next.t('rounds_put_on_hold_successfully'));

            return state;
        }

        case BrokenRoundsTypes.GET_BROKEN_ROUNDS_COUNT_SUCCESS: {
            const { totalNumberOfElements, roundUuids, sessionIds } = actions.payload;

            return {
                ...state,
                totalNumberOfElements,
                roundUuids,
                sessionIds,
            };
        }

        case BrokenRoundsTypes.RESOLVE_SESSION_SUCCESS: {
            Messages.success(i18next.t('session_was_successfully_resolved'));

            return state;
        }

        case BrokenRoundsTypes.RESOLVE_SESSION_FAILED: {
            Messages.error(i18next.t('session_was_not_resolved'));

            return state;
        }

        case BrokenRoundsTypes.STATUS_HISTORY_SUCCESS: {
            const { statusHistoryRecords } = actions.payload;

            return {
                ...state,
                statusHistory: statusHistoryRecords,
            };
        }

        default:
            return state;
    }
};

export default brokenRoundsReducer;
