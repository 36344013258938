import { IAction } from 'helpers/interfaces';
import { errorHandler } from 'helpers/errorHandler';
import { SearchTypes } from 'redux/actions/backoffice/search-actions';
import { UserTypes } from '../../actions/user-actions';

interface IError {
    error: string;
}

export const defaultErrorState: IError = {
    error: '',
};

const errorReducer = (state: IError = defaultErrorState, actions: IAction) => {
    switch (actions.type) {
        case UserTypes.API_ERROR: {
            return {
                ...state,
                error: errorHandler.getErrorText(actions.payload),
            };
        }

        case SearchTypes.REMOVE_SEARCH: {
            return state;
        }

        default:
            return defaultErrorState;
    }
};

export default errorReducer;
