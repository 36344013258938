import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './BrokenRoundsPageStyles.scss';
import { Empty, Modal, Pagination, DatePicker, Button, Space } from 'antd';
import { ContentComponent } from '../../Components/ContentComponent/ContentComponent';
import { Loader } from '../../Components/Loader/Loader';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    blockSession,
    getBrokenRounds,
    getBrokenRoundsCount,
    getBrokenRoundsJobStatus,
    resolveSession,
    retrySession,
    retrySessionBatch,
    resolveSessionBatch,
    onHoldAllRoundsBatch,
} from '../../redux/actions/configProvider/broken-rounds-actions';
import {
    getBrokenRoundsData,
    getBrokenRoundsMetadata,
    getJobStatusData,
    getJobUntilTimeData,
    getRoundUuidsData,
    getSessionIdsData,
    getTotalNumberOfRoundsData,
} from '../../redux/selectors/backoffice/broken-rounds-selector';
import SimplifiedCustomTable from '../../Components/Table/SimplifiedCustomTable';
import { brokenRoundsData } from '../Reports/columnsData';
import { MainButton, SecondaryButton } from '../../Components/CustomButtons/CustomButtons';
import { Overlay } from '../../Components/Overlay/Overlay';
import { IBrokenRoundData } from '../../helpers/interfaces';
import { HeaderTitleComponent } from '../../Components/ContentComponent/HeaderTitleComponent';
import {LoadingOutlined, SyncOutlined, CloseCircleOutlined} from '@ant-design/icons/lib';
import { Select } from 'antd';
import StatusHistory from './StatusHistory';
import { SERVER_URL } from '../../utils/config';
import dayjs from 'dayjs';
import { getLoginRequestHeader, Messages } from '../../utils';
import Search from "antd/es/input/Search";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface IBrokenRoundsPagingInfo {
    pageNumber: number;
    pageSize: number;
    sortBy: string;
    order: string;
}

interface IBrokenRoundsMetadata {
    pagingInfo: IBrokenRoundsPagingInfo;
    totalNumberOfElements: number;
}

interface IProps {
    data: IBrokenRoundData[];
    metadata: IBrokenRoundsMetadata;
    getBrokenRounds: Function;
    getBrokenRoundsCount: Function;
    resolveSession: Function;
    retrySession: Function;
    blockSession: Function;
    retrySessionBatch: Function;
    resolveSessionBatch: Function;
    onHoldRoundsBatch: Function;
    getBrokenRoundsJobStatus: Function;
    totalNumberOfElements: number | null;
    sessionIds: number[] | null;
    roundUuids: string[] | null;
    isJobRunning: boolean;
}

interface IBrokenRoundRequestBody {
    externalPlayerId: string | null;
    playerSession: string | null;
    roundUuid: string | null;
    playerId: number | null;
    sessionId: number | null;
    currencyCode: string | null;
    roundStatuses: string[] | null;
    transactionUuid: string | null;
    roundStartedFrom: string | null;
    roundStartedTo: string | null;
    roundFailedFrom: string | null;
    roundFailedTo: string | null;
    brandNames: string[] | null;
    operatorName: string | null;
    pagingInfo: IBrokenRoundsPagingInfo;
}

const DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE = 100;
const PAGE_SIZE_ARRAY = ['10', '20', '50', '100', '1000'];

const initialBodyRequest: IBrokenRoundRequestBody = {
    externalPlayerId: null,
    pagingInfo: {
        pageNumber: 1,
        pageSize: DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE,
        sortBy: 'id',
        order: 'DESCENDING',
    },
    playerId: null,
    playerSession: null,
    roundUuid: null,
    sessionId: null,
    roundStatuses: null,
    currencyCode: null,
    transactionUuid: null,
    roundStartedFrom: null,
    roundStartedTo: null,
    roundFailedFrom: null,
    roundFailedTo: null,
    brandNames: null,
    operatorName: null,
};

const initialRoundInfo: IBrokenRoundData = {
    brandId: 0,
    brandName: '',
    failReason: '',
    failedRoundUuid: '',
    gameId: 0,
    gameName: '',
    id: 0,
    operatorId: 0,
    playerId: 0,
    playerSession: '',
    roundStatus: '',
    walletErrorCode: 0,
    walletErrorDescription: '',
    walletErrorDetails: '',
    roundUuid: '',
    sessionId: 0,
    startId: 0,
    transactionType: '',
    externalPlayerId: '',
    amount: 0,
    currencyCode: '',
    created: '',
    transactionUuid: '',
    roundStartedAt: '',
    roundFailedAt: '',
};



const BrokenRoundsPage = (props: IProps) => {
    const { t } = useTranslation();
    const {
        data,
        metadata,
        getBrokenRounds,
        getBrokenRoundsCount,
        resolveSession,
        retrySession,
        blockSession,
        totalNumberOfElements,
        retrySessionBatch,
        onHoldRoundsBatch,
        sessionIds,
        roundUuids,
        resolveSessionBatch,
        getBrokenRoundsJobStatus,
        isJobRunning,
    } = props;
    const defaultRoundStartedFrom = dayjs()
        .subtract(7, 'days')
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
    const defaultRoundFailedTo = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const [requestBody, setRequestBody] = useState({
        ...initialBodyRequest,
        roundStatuses: ['ON_RETRY', 'BLOCKED'],
        roundStartedFrom: defaultRoundStartedFrom,
        roundFailedTo: defaultRoundFailedTo,
    });
    const [roundInfo, setRoundInfo] = useState({ ...initialRoundInfo });
    const [roundInfoPopupVisible, changeInfoPopupVisibility] = useState(false);
    const [loadingResolve, setLoadingResolveStatus] = useState(false);
    const [loadingRetry, setLoadingRetryStatus] = useState(false);
    const [searchKey, setSearchKey] = useState('roundUuid');
    const [searchValue, setSearchValue] = useState('');
    const [onSearchStatus, setOnSearchStatus] = useState(false);
    const [resolveAllModalVisible, setResolveAllModalVisible] = useState(false);
    const [retryAllModalVisible, setRetryAllModalVisible] = useState(false);
    const [onHoldAllModalVisible, setOnHoldAllModalVisible] = useState(false);
    const isRetryAllDisabled = () => {
        const validStatuses = ['BLOCKED', 'ON_HOLD'];
        return (
            isJobRunning ||
            requestBody.roundStatuses.length !== 1 ||
            !requestBody.roundStatuses.every((status) => validStatuses.includes(status))
        );
    };
    const isResolveDisabled = () => {
        const validStatuses = ['BLOCKED', 'ON_HOLD', 'ON_RETRY'];
        return (
            isJobRunning ||
            requestBody.roundStatuses.length !== 1 ||
            !requestBody.roundStatuses.every((status) => validStatuses.includes(status))
        );
    };
    const isOnHoldAllDisabled = () => {
        const validStatuses = ['ON_RETRY'];
        return (
            isJobRunning ||
            requestBody.roundStatuses.length !== 1 ||
            !requestBody.roundStatuses.every((status) => validStatuses.includes(status))
        );
    };



    const updateRequestBody = (newData: Partial<typeof requestBody>) => {
        setRequestBody((prevBody) => ({
            ...prevBody,
            ...newData,
            pagingInfo: {
                ...prevBody.pagingInfo,
                ...newData.pagingInfo,
            },
        }));
    };


    const showInfo = (roundData: IBrokenRoundData) => {
        setRoundInfo(roundData);
        changeInfoPopupVisibility(true);
    };

    const getSupplementedData = () => {
        data.forEach((round: any) => {
            round.callback = showInfo;
        });

        return data;
    };

    const getInfo = () => {
        const result = [];

        for (const key in roundInfo) {
            if (key !== 'callback') {
                result.push(
                    <div key={key} className={'broken-round-info__item'}>
                        <p className={'broken-round-info__title'}>
                            {key === 'id' ? t('broken_round_id') : t(key)}
                        </p>
                        <p className={'broken-round-info__value'}>
                            {roundInfo[key as keyof IBrokenRoundData]}
                        </p>
                    </div>,
                );
            }
        }

        result.push(<StatusHistory key={roundInfo.roundUuid} roundUuid={roundInfo.roundUuid} />);

        return result;
    };

    const onResolve = () => {
        setLoadingResolveStatus(true);
        resolveSession(roundInfo.sessionId);
        setTimeout(() => {
            setLoadingResolveStatus(false);
            changeInfoPopupVisibility(false);
            getBrokenRounds(requestBody);
        }, 1000);
    };

    const onPageChange = (pageNumber: number) => {
        const pagingInfo = { ...requestBody.pagingInfo };

        pagingInfo.pageNumber = pageNumber;
        setRequestBody({ ...requestBody, pagingInfo });

        getBrokenRounds({ ...requestBody, pagingInfo });
    };

    const onShowSizeChange = (pageNumber: number, size: number) => {
        const pagingInfo = requestBody.pagingInfo;

        pagingInfo.pageNumber = pageNumber;
        pagingInfo.pageSize = size;

        setRequestBody({ ...requestBody, pagingInfo });
        getBrokenRounds(requestBody);
    };

    const onSearch = (value: string) => {
        const regularExp = new RegExp('^[0-9]*$');

        if (searchKey === 'sessionId' && !regularExp.test(value)) {
            setSearchValue(t('input_a_number'));
            return;
        }

        if (searchValue.length > 0) {
            const body: any = {
                ...requestBody,
                pagingInfo: initialBodyRequest.pagingInfo,
                playerSession: null,
                roundUuid: null,
                sessionId: null,
                externalPlayerId: null,
                brandNames: null,
            };

            if (searchKey === 'brandNames') {
                body[searchKey] = value.split(",").map(s => s.trim());
            } else {
                body[searchKey] = value;
            }

            setRequestBody(body);
            setOnSearchStatus(true);

            getBrokenRounds(body);
        }
    };

    const onDateChange = (dates: any) => {
        const formatString = 'YYYY-MM-DDTHH:mm:ss';
        const roundStartedFrom = dates ? dates[0].startOf('day').format(formatString) : null;
        const roundFailedTo = dates ? dates[1].endOf('day').format(formatString) : null;

        setRequestBody({
            ...requestBody,
            roundStartedFrom,
            roundFailedTo,
            pagingInfo: {
                ...requestBody.pagingInfo,
                pageNumber: 1,
            },
        });

        getBrokenRounds({
            ...requestBody,
            roundStartedFrom,
            roundFailedTo,
            pagingInfo: {
                ...requestBody.pagingInfo,
                pageNumber: 1,
            },
        });
    };

    const onStatusFilterChange = (value: string) => {
        const status: [] | string[] = value === 'all' ? [] : [value];

        updateRequestBody({
            roundStatuses: value === 'ON_RETRY_BLOCKED' ? ['ON_RETRY', 'BLOCKED'] : status,
            pagingInfo: {
                ...requestBody.pagingInfo,
                pageNumber: 1,
            },
        });

        getBrokenRounds({
            ...requestBody,
            roundStatuses: value === 'ON_RETRY_BLOCKED' ? ['ON_RETRY', 'BLOCKED'] : status,
            pagingInfo: {
                ...requestBody.pagingInfo,
                pageNumber: 1,
            },
        });
    };

    const refreshAfterRetry = () => {
        setTimeout(() => {
            setLoadingRetryStatus(false);
            changeInfoPopupVisibility(false);
            getBrokenRounds(requestBody);
        }, 1000);
    };
    const onSearchKeyChange = (key: string) => {
        setSearchKey(key);
    };

    const searchFieldSuffix = () => (
        <CloseCircleOutlined
            className={`broken-rounds-search__clear${searchValue.length > 0 ? '__visible' : ''}`}
            onClick={() => {
                if (searchValue.length > 0) {
                    refreshReportAfterSearch();
                }
            }}
        />
    );

    const refreshReportAfterSearch = () => {
        const { roundFailedTo, roundStartedFrom, roundStatuses } = requestBody;
        setSearchValue('');
        setRequestBody({
            ...initialBodyRequest,
            roundStartedFrom,
            roundFailedTo,
            roundStatuses,
        });
        getBrokenRounds({
            ...initialBodyRequest,
            roundStartedFrom,
            roundFailedTo,
            roundStatuses,
        });
        setOnSearchStatus(false);
    };

    const searchOnChange = (e: any) => {
        if (onSearchStatus && e.target.value.length < 1) {
            refreshReportAfterSearch();
        } else {
            setSearchValue(e.target.value);
        }
    };

    const onRetry = () => {
        setLoadingRetryStatus(true);
        retrySession(roundInfo.roundUuid);
        refreshAfterRetry();
    };

    const onBlock = () => {
        setLoadingRetryStatus(true);
        blockSession(roundInfo.roundUuid);
        refreshAfterRetry();
    };

    const reloadData = () => {
        getBrokenRounds(requestBody);
    };

    const handleResolveAll = () => {
        getBrokenRoundsCount(requestBody);
        setResolveAllModalVisible(true);
    };

    const handleRetryAll = () => {
        getBrokenRoundsCount(requestBody);
        setRetryAllModalVisible(true);
    };

    const handleOnHoldAll = () => {
        getBrokenRoundsCount(requestBody);
        setOnHoldAllModalVisible(true);
    };


    const handleExport = async () => {
        const body = {
            ...requestBody,
            exportFormat: 'CSV',
            totalNumberOfElements: metadata.totalNumberOfElements.toString(),
        };

        try {
            const response = await fetch(`${SERVER_URL}/rounds/broken-rounds/export`, {
                method: 'POST',
                headers: {
                    ...getLoginRequestHeader('application/json'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                let errorMessage = 'Failed to export data';

                try {
                    const errorData = await response.json();
                    if (errorData.error.message) {
                        errorMessage = errorData.error.message;
                    } else {
                        errorMessage = JSON.stringify(errorData);
                    }
                } catch (jsonError) {
                    errorMessage = await response.text();
                }

                throw new Error(errorMessage);
            }

            const contentDisposition = response.headers.get('Content-Disposition');
            let filename = 'export.csv';
            if (contentDisposition && contentDisposition.includes('filename=')) {
                filename = contentDisposition.split('filename=')[1].trim();
                filename = filename.replace(/^"|"$/g, '');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error: any) {
            Messages.error('Error exporting data: ' + error.message);
        }
    };

    useEffect(() => {
        getBrokenRounds(requestBody);
    }, [getBrokenRounds, requestBody]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        let timeoutId: NodeJS.Timeout | null = null;

        intervalId = setInterval(() => {
            getBrokenRoundsJobStatus();
        }, 30000);

        timeoutId = setTimeout(
            () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            },
            30 * 60 * 1000,
        );

        getBrokenRoundsJobStatus();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [getBrokenRoundsJobStatus]);

    return (
        <ContentComponent
            header={
                <>
                    <HeaderTitleComponent
                        title={t('broken_rounds')}
                        customBreadcrumbs={<div>{t('all')}</div>}
                    />
                    <Space.Compact
                        className="broken-rounds-search__wrapper"
                        style={{ marginBottom: '8px', display: 'flex', width: '100%' }}
                    >
                        <Button
                            icon={<SyncOutlined />}
                            onClick={reloadData}
                            className="broken-rounds-search__refresh"
                        />
                        <RangePicker
                            className="broken-rounds-search__datepicker"
                            onChange={(e: any) => onDateChange(e)}
                            defaultValue={[dayjs().subtract(7, 'days'), dayjs()]}
                        />
                        <Select
                            style={{ width: '170px' }}
                            className="broken-rounds-search__status"
                            defaultValue="ON_RETRY_BLOCKED"
                            onChange={onStatusFilterChange}
                            popupMatchSelectWidth={true}
                        >
                            <Option value="all">{t('all')}</Option>
                            <Option value="ON_RETRY_BLOCKED">{t('on_retry&blocked')}</Option>
                            <Option value="ON_RETRY">{t('on_retry')}</Option>
                            <Option value="BLOCKED">{t('blocked')}</Option>
                            <Option value="ON_HOLD">{t('on_hold')}</Option>
                            <Option value="RESOLVED">{t('resolved')}</Option>
                            <Option value="MANUALLY_RESOLVED">{t('manually_resolved')}</Option>
                            <Option value="NOT_BLOCKED">{t('not_blocked')}</Option>
                        </Select>
                        <Button onClick={handleExport}>Export to CSV</Button>
                        <Button disabled={isResolveDisabled()} onClick={handleResolveAll}>
                            Resolve all
                        </Button>
                        <Button disabled={isRetryAllDisabled()} onClick={handleRetryAll}>
                            Retry all
                        </Button>
                        <Button disabled={isOnHoldAllDisabled()} onClick={handleOnHoldAll}>
                            Put on hold all
                        </Button>
                        {isJobRunning && (
                            <p
                                style={{
                                    display: 'flex',
                                    margin: '0',
                                    marginLeft: '8px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <LoadingOutlined />{' '}
                                <span style={{ marginLeft: '8px' }}>Job is running</span>
                            </p>
                        )}
                    </Space.Compact>
                    <Modal
                        open={resolveAllModalVisible}
                        title={'Resolve All Sessions'}
                        onOk={async () => {
                            if (sessionIds && sessionIds.length > 0)
                                resolveSessionBatch({sessionIds});
                            setResolveAllModalVisible(false);
                        }}
                        onCancel={() => setResolveAllModalVisible(false)}
                        okText={t('Resolve all')}
                        cancelText={t('Cancel')}
                    >
                        {totalNumberOfElements !== null ? (
                            <p>
                                {'Are you sure you want to resolve ' +
                                    totalNumberOfElements +
                                    ' sessions?'}
                            </p>
                        ) : (
                            <p>{'Loading total number of elements'}</p>
                        )}
                    </Modal>
                    <Modal
                        open={retryAllModalVisible}
                        title={'Retry All Sessions'}
                        onOk={async () => {
                            if (roundUuids && roundUuids.length > 0)
                                retrySessionBatch({roundUuids});

                            setRetryAllModalVisible(false);
                        }}
                        onCancel={() => setRetryAllModalVisible(false)}
                        okText={t('Retry all')}
                        cancelText={t('Cancel')}
                    >
                        {totalNumberOfElements !== null ? (
                            <p>
                                {'Are you sure you want to retry ' +
                                    totalNumberOfElements +
                                    ' sessions?'}
                            </p>
                        ) : (
                            <p>{'Loading total number of elements'}</p>
                        )}
                    </Modal>
                    <Modal
                        open={onHoldAllModalVisible}
                        title={'Put on hold all broken rounds'}
                        onOk={async () => {
                            if (roundUuids && roundUuids.length > 0)
                                onHoldRoundsBatch({roundUuids});

                            setOnHoldAllModalVisible(false);
                        }}
                        onCancel={() => setOnHoldAllModalVisible(false)}
                        okText={t('Put on hold all')}
                        cancelText={t('Cancel')}
                    >
                        {totalNumberOfElements !== null ? (
                            <p>
                                {'Are you sure you want to put on hold ' +
                                    totalNumberOfElements +
                                    ' broken rounds?'}
                            </p>
                        ) : (
                            <p>{'Loading total number of elements'}</p>
                        )}
                    </Modal>
                    <div className="filter-wrapper">
                        <Space.Compact
                            className="broken-rounds-search__wrapper"
                            style={{ marginBottom: '8px', display: 'flex', width: '42%' }}
                        >
                        <Select
                            style={{ width: '160px' }}
                            defaultValue={t('roundUuid')}
                            className="broken-rounds-search__select"
                            onChange={onSearchKeyChange}
                            popupMatchSelectWidth={true}
                        >
                            <Option value="roundUuid">{t('roundUuid')}</Option>
                            <Option value="externalPlayerId">{t('externalPlayerId')}</Option>
                            <Option value="playerSession">{t('playerSession')}</Option>
                            <Option value="sessionId">{t('sessionId')}</Option>
                            <Option value="brandNames">{t('brandName')}</Option>
                            <Option value="operatorName">{t('operatorName')}</Option>
                        </Select>
                        <Search
                            value={searchValue}
                            onChange={searchOnChange}
                            suffix={searchFieldSuffix()}
                            className="broken-rounds-search__search-field"
                            placeholder={
                                searchKey === 'sessionId'
                                    ? t('input_a_number')
                                    : t('enter_search_value')
                            }
                            onSearch={onSearch}
                            enterButton
                            style={{ flex: 1 }}
                        />
                        </Space.Compact>
                    </div>
                </>
            }
            innerContent={
                data ? (
                    data.length ? (
                        <>
                            <SimplifiedCustomTable
                                dataSource={getSupplementedData()}
                                columns={brokenRoundsData}
                            />
                            <Pagination
                                style={{ paddingTop: '8px', paddingBottom: '8px' }}
                                onChange={onPageChange}
                                total={metadata.totalNumberOfElements}
                                current={metadata.pagingInfo.pageNumber}
                                pageSize={metadata.pagingInfo.pageSize}
                                pageSizeOptions={PAGE_SIZE_ARRAY}
                                showSizeChanger
                                onShowSizeChange={onShowSizeChange}
                            />
                            <Modal
                                className="broken-round-info__modal"
                                title={t('info')}
                                open={roundInfoPopupVisible}
                                onOk={() => changeInfoPopupVisibility(false)}
                                onCancel={() => changeInfoPopupVisibility(false)}
                                closable={false}
                                footer={[
                                    (roundInfo.roundStatus.includes('BLOCKED') ||
                                        roundInfo.roundStatus.includes('ON_HOLD')) && (
                                        <SecondaryButton key={'retry'} onClick={onRetry}>
                                            <>
                                                {loadingRetry ? (
                                                    <LoadingOutlined
                                                        style={{
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                ) : null}
                                                {t('retry')}
                                            </>
                                        </SecondaryButton>
                                    ),
                                    roundInfo.roundStatus.includes('ON_RETRY') && (
                                        <SecondaryButton key={'block'} onClick={onBlock}>
                                            <>
                                                {loadingRetry ? (
                                                    <LoadingOutlined
                                                        style={{
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                ) : null}
                                                Put on hold
                                            </>
                                        </SecondaryButton>
                                    ),
                                    <SecondaryButton
                                        key={'resolve_session'}
                                        onClick={onResolve}
                                        disabled={roundInfo.roundStatus.includes('RESOLVED')}
                                    >
                                        <>
                                            {loadingResolve ? (
                                                <LoadingOutlined
                                                    style={{
                                                        marginRight: '8px',
                                                    }}
                                                />
                                            ) : null}
                                            Resolve Manually
                                        </>
                                    </SecondaryButton>,
                                    <MainButton
                                        key={'ok'}
                                        onClick={() => changeInfoPopupVisibility(false)}
                                    >
                                        {t('ok')}
                                    </MainButton>,
                                ]}
                            >
                                <div className="broken-round-info">{getInfo()}</div>
                            </Modal>
                            <Overlay
                                isVisible={roundInfoPopupVisible}
                                switchState={changeInfoPopupVisibility}
                            />
                        </>
                    ) : (
                        <Empty description={t('no_data')} />
                    )
                ) : (
                    <Loader style={{ height: '90vh' }} />
                )
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getBrokenRoundsData(state),
    metadata: getBrokenRoundsMetadata(state),
    totalNumberOfElements: getTotalNumberOfRoundsData(state),
    sessionIds: getSessionIdsData(state),
    roundUuids: getRoundUuidsData(state),
    isJobRunning: getJobStatusData(state),
    jobUntilTime: getJobUntilTimeData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBrokenRounds: (data: IBrokenRoundRequestBody) => dispatch(getBrokenRounds(data)),
    getBrokenRoundsCount: (data: any) => dispatch(getBrokenRoundsCount(data)),
    resolveSession: (data: number) => dispatch(resolveSession(data)),
    retrySession: (data: string) => dispatch(retrySession(data)),
    blockSession: (data: string) => dispatch(blockSession(data)),
    retrySessionBatch: (data: string) => dispatch(retrySessionBatch(data)),
    onHoldRoundsBatch: (data: string) => dispatch(onHoldAllRoundsBatch(data)),
    resolveSessionBatch: (data: string) => dispatch(resolveSessionBatch(data)),
    getBrokenRoundsJobStatus: () => dispatch(getBrokenRoundsJobStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrokenRoundsPage);
