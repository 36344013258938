import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, Location, useParams } from 'react-router-dom';

type WithRouterProps = {
    navigate: ReturnType<typeof useNavigate>;
    location: Location;
    history: {
        push: ReturnType<typeof useNavigate>;
        listen: (callback: (location: Location) => void) => () => void;
    };
};

export const withRouter = (Component: React.ComponentType<any & WithRouterProps>) => {
    const WithRouterWrapper = (props: any) => {
        const params = useParams();
        const navigate = useNavigate();
        const location = useLocation();
        const listeners = useRef<Array<(location: Location) => void>>([]);

        const listen = (callback: (location: Location) => void) => {
            listeners.current.push(callback);

            return () => {
                listeners.current = listeners.current.filter((l) => l !== callback);
            };
        };

        useEffect(() => {
            listeners.current.forEach((callback) => callback(location));
        }, [location]);

        const history = {
            push: navigate,
            listen,
        };

        return (
            <Component
                {...props}
                navigate={navigate}
                location={location}
                history={history}
                params={params}
            />
        );
    };

    WithRouterWrapper.displayName = `WithRouter(${getDisplayName(Component)})`;

    return WithRouterWrapper;
};

function getDisplayName(Component: React.ComponentType<any>): string {
    return Component.displayName || Component.name || 'Component';
}
