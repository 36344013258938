import { createSelector } from 'reselect';
import { dataFormatterUtils, sort } from 'utils';

const getGamesData = (state: any) => state.gamesReportReducer.games;
const getGamesTotalLineData = (state: any) => state.gamesReportReducer.total;
const getGamesSortedData = (state: any) => state.gamesReportReducer.sortedGames;

export const getRawGamesData = getGamesData;

export const getGames = createSelector(
    getGamesData,
    (state) =>
        state &&
        sort(
            {
                keyToSort: 'gameName',
                sortState: 'asc',
            },
            dataFormatterUtils.getFormattedData(state),
        ),
);
export const getSortedGames = createSelector(getGamesSortedData, (state) => state);
export const getGamesTotalLine = createSelector(getGamesTotalLineData, (state) => state);
