import * as React from 'react';
import '../Containers/Charts/ChartStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactEcharts from 'echarts-for-react';
import { dateFormatter } from 'utils';
import { Checkbox } from 'antd';
import { IEChartSeries } from 'helpers/interfaces';
import { MobileChartConstants } from 'mobile/MobileChartConstants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IProps {
    data: any[];
}

type ChartKey = 'bet' | 'win' | 'net';

interface IState {
    activeCharts: ChartKey[];
}

const financialCharts: ChartKey[] = ['bet', 'win', 'net'];

const defaultState: IState = {
    activeCharts: financialCharts,
};

const chartColors: Record<ChartKey, string> = {
    bet: '#00d9a0',
    win: '#3579ff',
    net: '#ffb600',
};

class CasinoMobileChart extends React.Component<IProps & WithTranslation, IState> {
    state: IState = defaultState;

    echarts_react: any = React.createRef();

    getOptions = () =>
        this.props.data && {
            color: this.getChartColors(),
            xAxis: this.getXAxis(),
            yAxis: this.getYAxis(),
            series: this.getSeries(),
            ...MobileChartConstants.commonOptions,
        };

    getChartColors = () => this.state.activeCharts.map((key: ChartKey) => chartColors[key]);

    getXAxis = () => {
        const axisPointer = {
            value: dateFormatter.getFormattedForReportDate(this.props.data[0].from),
            snap: true,
            lineStyle: {
                color: '#D8D8D8',
                opacity: 1,
                width: 1,
            },
            label: {
                show: true,
                padding: [0, 10],
                backgroundColor: 'white',
                borderColor: 'white',
                margin: -288,
                color: 'rgba(11,16,34,0.3)',
                fontSize: 14,
                shadowBlur: 0,
            },
            handle: {
                show: true,
                color: '#dad9d9',
                size: 30,
                margin: 15,
            },
        };

        return [
            {
                ...MobileChartConstants.commonAxisXStyles,
                axisPointer: { ...axisPointer },
                data: this.getDates(),
            },
        ];
    };

    getDates = () =>
        this.props.data.map((item) => dateFormatter.getFormattedForReportDate(item.from));

    getYAxis = () =>
        this.state.activeCharts.map(() => ({
            ...MobileChartConstants.commonAxisYStyles,
        }));

    getChartDataByKey = (key: string) => {
        const chartData: number[] = [];

        this.props.data.forEach((item) => chartData.push(item[key]));

        return chartData;
    };

    getSingleSeries = (key: string, axisIndex: number) => ({
        name: this.props.t(key),
        type: 'line',
        smooth: true,
        yAxisIndex: axisIndex,
        data: this.getChartDataByKey(key),
    });

    getSeries = () => {
        const { activeCharts } = this.state;
        const series: IEChartSeries[] = [];

        activeCharts.forEach((key: string) => series.push(this.getSingleSeries(key, 0)));

        return series;
    };

    onChartSelectChange = (e: CheckboxChangeEvent) => {
        const { activeCharts } = this.state;
        const { checked, value } = e.target;
        const currentCharts = [...activeCharts];

        if (!checked && activeCharts.length === 1) {
            e.preventDefault();

            return;
        }

        if (checked) {
            if (activeCharts.length < 3) {
                currentCharts.push(value);
            } else {
                currentCharts[currentCharts.length - 1] = value;
            }
        } else {
            currentCharts.splice(currentCharts.indexOf(value), 1);
        }

        this.setState(() => ({ activeCharts: currentCharts }));
    };

    getChartSelectors = () => {
        const { activeCharts } = this.state;

        return (
            <div className="mobile-checkboxes">
                {financialCharts.map((key: ChartKey) => (
                    <Checkbox
                        className={key}
                        key={key}
                        value={key}
                        checked={activeCharts.includes(key)}
                        onChange={(e: CheckboxChangeEvent) => this.onChartSelectChange(e)}
                    >
                        {this.props.t(key)}
                    </Checkbox>
                ))}
            </div>
        );
    };

    componentDidUpdate = () => this.dispatchTooltipAction();

    componentDidMount = () => this.dispatchTooltipAction();

    dispatchTooltipAction = () => {
        const myChart = this.echarts_react.getEchartsInstance();

        myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: 0,
        });
    };

    render = () => {
        const { t } = this.props;

        return (
            <div className="chart mobile-chart consolidated">
                <h2 className="content-item__title">{t('trends_by_KPI')}</h2>
                {this.getChartSelectors()}
                <ReactEcharts
                    ref={(elem) => (this.echarts_react = elem)}
                    notMerge
                    option={this.getOptions()}
                />
            </div>
        );
    };
}

export default withTranslation()(CasinoMobileChart);
