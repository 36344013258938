export const BrokenRoundsTypes = {
    BROKEN_ROUNDS_REQUEST: 'BROKEN_ROUNDS_REQUEST',
    BROKEN_ROUNDS_SUCCESS: 'BROKEN_ROUNDS_SUCCESS',
    BROKEN_ROUNDS_FAILED: 'BROKEN_ROUNDS_FAILED',
    RESOLVE_SESSION_REQUEST: 'RESOLVE_SESSION_REQUEST',
    RESOLVE_SESSION_SUCCESS: 'RESOLVE_SESSION_SUCCESS',
    RESOLVE_SESSION_FAILED: 'RESOLVE_SESSION_FAILED',
    RETRY_SESSION_REQUEST: 'RETRY_SESSION_REQUEST',
    RETRY_SESSION_SUCCESS: 'RETRY_SESSION_SUCCESS',
    RETRY_SESSION_FAILED: 'RETRY_SESSION_FAILED',
    BLOCK_SESSION_REQUEST: 'BLOCK_SESSION_REQUEST',
    BLOCK_SESSION_SUCCESS: 'BLOCK_SESSION_SUCCESS',
    BLOCK_SESSION_FAILED: 'BLOCK_SESSION_FAILED',
    STATUS_HISTORY_REQUEST: 'STATUS_HISTORY_REQUEST',
    STATUS_HISTORY_SUCCESS: 'STATUS_HISTORY_SUCCESS',
    STATUS_HISTORY_FAILED: 'STATUS_HISTORY_FAILED',
    GET_BROKEN_ROUNDS_COUNT_REQUEST: 'GET_BROKEN_ROUNDS_COUNT_REQUEST',
    GET_BROKEN_ROUNDS_COUNT_SUCCESS: 'GET_BROKEN_ROUNDS_COUNT_SUCCESS',
    GET_BROKEN_ROUNDS_COUNT_FAILED: 'GET_BROKEN_ROUNDS_COUNT_FAILED',
    RETRY_SESSIONS_BATCH_REQUEST: 'RETRY_SESSIONS_BATCH_REQUEST',
    RETRY_SESSIONS_BATCH_SUCCESS: 'RETRY_SESSIONS_BATCH_SUCCESS',
    ON_HOLD_BROKEN_ROUNDS_BATCH_REQUEST: 'ON_HOLD_BROKEN_ROUNDS_BATCH_REQUEST',
    ON_HOLD_BROKEN_ROUNDS_BATCH_SUCCESS: 'ON_HOLD_BROKEN_ROUNDS_BATCH_SUCCESS',
    RETRY_SESSIONS_BATCH_FAILED: 'RETRY_SESSIONS_BATCH_FAILED',
    UNBLOCK_SESSIONS_BATCH_REQUEST: 'UNBLOCK_SESSIONS_BATCH_REQUEST',
    UNBLOCK_SESSIONS_BATCH_SUCCESS: 'UNBLOCK_SESSIONS_BATCH_SUCCESS',
    UNBLOCK_SESSIONS_BATCH_FAILED: 'UNBLOCK_SESSIONS_BATCH_FAILED',
    BROKEN_ROUNDS_JOB_STATUS_REQUEST: 'BROKEN_ROUNDS_JOB_STATUS_REQUEST',
    BROKEN_ROUNDS_JOB_STATUS_SUCCESS: 'BROKEN_ROUNDS_JOB_STATUS_SUCCESS',
    BROKEN_ROUNDS_JOB_STATUS_FAILED: 'BROKEN_ROUNDS_JOB_STATUS_SUCCESS',
};

export const getBrokenRounds = (payload?: any) => ({
    type: BrokenRoundsTypes.BROKEN_ROUNDS_REQUEST,
    payload,
});
export const getBrokenRoundsSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.BROKEN_ROUNDS_SUCCESS,
    payload,
});
export const getBrokenRoundsCount = (payload?: any) => ({
    type: BrokenRoundsTypes.GET_BROKEN_ROUNDS_COUNT_REQUEST,
    payload,
});
export const getBrokenRoundsCountSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.GET_BROKEN_ROUNDS_COUNT_SUCCESS,
    payload,
});
export const resolveSession = (payload?: any) => ({
    type: BrokenRoundsTypes.RESOLVE_SESSION_REQUEST,
    payload,
});
export const resolveSessionSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.RESOLVE_SESSION_SUCCESS,
    payload,
});
export const retrySession = (payload?: any) => ({
    type: BrokenRoundsTypes.RETRY_SESSION_REQUEST,
    payload,
});
export const retrySessionSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.RETRY_SESSION_SUCCESS,
    payload,
});
export const blockSession = (payload?: any) => ({
    type: BrokenRoundsTypes.BLOCK_SESSION_REQUEST,
    payload,
});
export const blockSessionSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.BLOCK_SESSION_SUCCESS,
    payload,
});
export const getStatusHistory = (payload?: any) => ({
    type: BrokenRoundsTypes.STATUS_HISTORY_REQUEST,
    payload,
});
export const getStatusHistorySuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.STATUS_HISTORY_SUCCESS,
    payload,
});
export const retrySessionBatch = (payload?: any) => ({
    type: BrokenRoundsTypes.RETRY_SESSIONS_BATCH_REQUEST,
    payload,
});
export const retrySessionBatchSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.RETRY_SESSIONS_BATCH_SUCCESS,
    payload,
});
export const onHoldAllRoundsBatch = (payload?: any) => ({
    type: BrokenRoundsTypes.ON_HOLD_BROKEN_ROUNDS_BATCH_REQUEST,
    payload,
});
export const onHoldAllRoundsBatchSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.ON_HOLD_BROKEN_ROUNDS_BATCH_SUCCESS,
    payload,
});
export const resolveSessionBatch = (payload?: any) => ({
    type: BrokenRoundsTypes.UNBLOCK_SESSIONS_BATCH_REQUEST,
    payload,
});
export const unblockSessionBatchSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.UNBLOCK_SESSIONS_BATCH_SUCCESS,
    payload,
});
export const getBrokenRoundsJobStatus = (payload?: any) => ({
    type: BrokenRoundsTypes.BROKEN_ROUNDS_JOB_STATUS_REQUEST,
    payload,
});
export const getBrokenRoundsJobStatusSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.BROKEN_ROUNDS_JOB_STATUS_SUCCESS,
    payload,
});
